import React, { useEffect, useState } from 'react';
import { useStore } from '../../hooks';
import {
  ICourseCompetencyModel,
  ICourseCompetencyTemplateModel,
  ICourseModel,
  IStudentCourseCompetencyModel,
  IStudentThinkificCourseEnrolmentModel,
} from '../../../domain/store/StudentStore';
import {
  Backdrop,
  Box,
  Checkbox,
  CircularProgress,
  Fade,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TableContainer from '@material-ui/core/TableContainer/TableContainer';
import AddIcon from '@material-ui/icons/Add';
import _ from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import { Clear, Remove } from '@material-ui/icons';
import styles from './Competencies.module.scss';
import EmailIcon from '@material-ui/icons/Email';

interface feedbackModal {
  // studentCompetency: IStudentCourseCompetencyModel | null,
  show: boolean;
  competencyId: number | null;
  studentId: number | null;
  isNyc: boolean;
  templateId: number;
  subject: string;
  body: string;
}

const initialFeedbackModalState: feedbackModal = {
  show: false,
  studentId: null,
  competencyId: null,
  isNyc: false,
  // studentCompetency: null,
  templateId: 0,
  subject: '',
  body: '',
};

export const Competencies: React.FC = function () {
  const { studentStore, security } = useStore();

  const [showSpinner, setShowSpinner] = React.useState(false);
  const [courses, setCourses] = useState<ICourseModel[]>([]);
  const [selectedCourseId, setSelectedCourseId] = useState<number | null>(null);
  const [students, setStudents] = useState<IStudentThinkificCourseEnrolmentModel[]>([]);
  const [selectedStudents, setSelectedStudents] = useState<IStudentThinkificCourseEnrolmentModel[]>(
    []
  );
  const [courseCompetencies, setCourseCompetencies] = useState<ICourseCompetencyModel[]>([]);
  const [studentCompetencies, setStudentCompetencies] = useState<IStudentCourseCompetencyModel[]>(
    []
  );

  const [showFeedbackModal, setShowFeedbackModal] = useState<feedbackModal>(
    initialFeedbackModalState
  );

  const [
    showStudentCompetencyFeedbackModal,
    setShowStudentCompetencyFeedbackModal,
  ] = useState<IStudentCourseCompetencyModel | null>(null);

  useEffect(() => {
    // setShowSpinner(true);
    // studentStore
    //   .getCourses()
    //   .then((courses) => {
    //     setCourses(courses);
    //   })
    //   .finally(() => {
    //     setShowSpinner(false);
    //   });

    // short term hack while thinkigic API isn't working.
    setCourses([
      { id: 2611165, name: 'Matwork & Reformer Certification' } as ICourseModel,
      { id: 2752488, name: 'Mentor Affiliate Bridging Certification' } as ICourseModel,
    ]);
  }, [studentStore, setCourses]);

  useEffect(() => {
    if (selectedCourseId === null) return;

    setSelectedStudents([]);
    setStudents([]);

    setShowSpinner(true);

    Promise.all([
      studentStore.getEnrollmentsForCourse(selectedCourseId),
      studentStore.getCompetenciesForCourse(selectedCourseId),
    ])
      .then(([enrollments, competencies]) => {
        setStudents(enrollments);

        competencies.sort(
          (a: ICourseCompetencyModel, b: ICourseCompetencyModel) => a.displayOrder - b.displayOrder
        );

        let grouped = competencies.reduce(
          (
            grouped: {
              [key: string]: ICourseCompetencyModel[];
            },
            competency: ICourseCompetencyModel
          ) => {
            let key = competency.group1 || 'null'; // handle null or undefined group1
            if (!grouped[key]) {
              grouped[key] = [];
            }
            grouped[key].push(competency);
            return grouped;
          },
          {}
        );

        let reducedGroups: ICourseCompetencyModel[] = Object.values(
          grouped
        ).flat() as ICourseCompetencyModel[];

        setCourseCompetencies(reducedGroups);
      })
      .finally(() => {
        setShowSpinner(false);
      });
  }, [studentStore, selectedCourseId]);

  useEffect(() => {
    if (selectedStudents.length === 0 || !selectedCourseId) return;

    setShowSpinner(true);
    studentStore
      .getCourseCompetenciesForStudents(
        selectedStudents.map((s: IStudentThinkificCourseEnrolmentModel) => s.studentId),
        selectedCourseId
      )
      .then((competencies: IStudentCourseCompetencyModel[]) => {
        setStudentCompetencies(competencies);
      })
      .finally(() => {
        setShowSpinner(false);
      });
  }, [studentStore, selectedCourseId, selectedStudents]);

  const handleCompetencyChange = (
    studentId: number,
    competencyId: number,
    checked: boolean,
    email: string,
    enrollmentId: number
  ) => {
    console.log(studentId, competencyId, checked);

    const matchingStudentCompetency = studentCompetencies.filter(
      (sc) => sc.studentId === studentId && sc.courseCompetencyId === competencyId
    );

    let studentCompetencyId = 0;

    if (matchingStudentCompetency.length === 1) {
      studentCompetencyId = matchingStudentCompetency[0].id;
    }

    setShowSpinner(true);

    studentStore
      .upsertStudentCourseCompetency({
        courseCompetencyId: competencyId,
        studentId: studentId,
        status: checked ? 'C' : 'NYC',
        id: studentCompetencyId,
        courseEnrolmentId: enrollmentId,
        studentEmail: email,
        note: '',
        countTypeValue: null,
        feedbackSent: '',
      })
      .then((result) => {
        if (studentCompetencyId === 0) {
          setStudentCompetencies([...studentCompetencies, result]);
        } else {
          const updatedStudentCompetencies = studentCompetencies.filter(
            (sc) => !(sc.studentId === studentId && sc.courseCompetencyId === competencyId)
          );
          updatedStudentCompetencies.push(result);
          setStudentCompetencies(updatedStudentCompetencies);
        }

        if (checked) {
          setShowFeedbackModal({
            ...initialFeedbackModalState,
            isNyc: false,
            studentId: studentId,
            competencyId: competencyId,
            show: true,
          });
        }
      })
      .finally(() => {
        setShowSpinner(false);
      });
  };

  const handleCompetencyCountChange = (
    studentId: number,
    competencyId: number,
    value: number,
    email: string,
    enrollmentId: number
  ) => {
    const matchingStudentCompetency = studentCompetencies.filter(
      (sc) => sc.studentId === studentId && sc.courseCompetencyId === competencyId
    );

    let studentCompetencyId = 0;

    if (matchingStudentCompetency.length === 1) {
      studentCompetencyId = matchingStudentCompetency[0].id;
    }

    let competencyCountRequirement =
      _.first(courseCompetencies.filter((c) => c.id === competencyId))?.countTypeRequirement || 0;

    if (value > competencyCountRequirement || value < 0) {
      console.log('Competency Count value cannot be below 0 or above the requirement.');
      return;
    }

    setShowSpinner(true);

    studentStore
      .upsertStudentCourseCompetency({
        courseCompetencyId: competencyId,
        studentId: studentId,
        status: value === competencyCountRequirement ? 'C' : 'NYC',
        id: studentCompetencyId,
        courseEnrolmentId: enrollmentId,
        studentEmail: email,
        note: '',
        countTypeValue: value,
        feedbackSent: '',
      })
      .then((result) => {
        if (studentCompetencyId === 0) {
          setStudentCompetencies([...studentCompetencies, result]);
        } else {
          const updatedStudentCompetencies = studentCompetencies.filter(
            (sc) => !(sc.studentId === studentId && sc.courseCompetencyId === competencyId)
          );
          updatedStudentCompetencies.push(result);
          setStudentCompetencies(updatedStudentCompetencies);
        }

        if (value === competencyCountRequirement) {
          setShowFeedbackModal({
            ...initialFeedbackModalState,
            isNyc: false,
            studentId: studentId,
            competencyId: competencyId,
            show: true,
          });
        }
      })
      .finally(() => {
        setShowSpinner(false);
      });
  };

  const replacePlaceholders = (text: string) => {
    return text
      .replace(
        /{{studentFirstName}}/g,
        selectedStudents.find((s) => s.studentId === showFeedbackModal.studentId)
          ?.firstName as string
      )
      .replace(
        /{{competencyTitle}}/g,
        courseCompetencies.find((cc) => cc.id === showFeedbackModal.competencyId)
          ?.competencyTitle as string
      )
      .replace(
        /{{competencyGroup}}/g,
        courseCompetencies.find((cc) => cc.id === showFeedbackModal.competencyId)?.group1 as string
      )
      .replace(/{{trainerFirstName}}/g, security.getSignedInName()?.split(' ')[0] ?? '');
  };

  const sendFeedbackEmail = () => {
    setShowSpinner(true);
    studentStore
      .sendFeedbackEmail(
        showFeedbackModal.studentId!,
        showFeedbackModal.competencyId!,
        showFeedbackModal.subject,
        showFeedbackModal.body
      )
      .then((result: IStudentCourseCompetencyModel) => {
        if (result) {
          const existingStudentCompetency = studentCompetencies.find(
            (sc) =>
              sc.studentId === showFeedbackModal.studentId &&
              sc.courseCompetencyId === showFeedbackModal.competencyId
          );

          if (!existingStudentCompetency) {
            setStudentCompetencies([...studentCompetencies, result]);
          } else {
            const updatedStudentCompetencies = studentCompetencies.filter(
              (sc) => existingStudentCompetency.id !== sc.id
            );

            updatedStudentCompetencies.push(result);
            setStudentCompetencies(updatedStudentCompetencies);
          }
        }
        setShowFeedbackModal(initialFeedbackModalState);
      })
      .finally(() => {
        setShowSpinner(false);
      });
  };

  return (
    <div className={styles.container}>
      <Backdrop className={styles.backdrop} open={showSpinner}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <h1>Competencies</h1>

      <p>Use this page for setting student competencies for Thinkific courses.</p>

      <Grid container spacing={3}>
        <Grid item xs={6} sm={6}>
          <FormControl fullWidth={true}>
            <InputLabel id="course-select-label">Course</InputLabel>
            <Select
              fullWidth
              labelId="course-select-label"
              id="course-select"
              value={selectedCourseId ?? 0}
              onChange={(e, newValue) => setSelectedCourseId(e.target.value as number)}>
              <MenuItem value="0" disabled={true}>
                Select Course
              </MenuItem>
              {courses.map((course: ICourseModel, i: number) => {
                return (
                  <MenuItem key={i} value={course.id}>
                    {course.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6} sm={6}>
          <FormControl fullWidth={true}>
            <Autocomplete
              multiple
              disabled={selectedCourseId === null}
              id="student-select"
              options={students}
              getOptionLabel={(option) =>
                option.firstName + ' ' + option.lastName + ' (' + option.email + ')'
              }
              defaultValue={[]}
              value={selectedStudents}
              onChange={(event, newValue) => {
                setSelectedStudents(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="Multiple values" placeholder="" />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Student</TableCell>
                  {courseCompetencies.map((c, i) => (
                    <TableCell key={i}>
                      {c.group1}{' '}
                      {c.group1 && (
                        <>
                          <br />
                        </>
                      )}{' '}
                      {c.competencyTitle}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedStudents.map((s, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      {s.firstName} {s.lastName} ({s.email})
                    </TableCell>
                    {courseCompetencies.map((c, i) => (
                      <TableCell key={i}>
                        {c.competencyType === 'Checkbox' && (
                          <Checkbox
                            className={styles.checkbox}
                            checked={
                              studentCompetencies.filter(
                                (sc) =>
                                  sc.studentId === s.studentId &&
                                  sc.courseCompetencyId === c.id &&
                                  sc.status === 'C'
                              ).length > 0
                            }
                            onChange={(e) => {
                              handleCompetencyChange(
                                s.studentId,
                                c.id,
                                Boolean(e.target.checked),
                                s.email,
                                s.enrollmentId
                              );
                            }}
                          />
                        )}

                        {c.competencyType === 'Count' && (
                          <>
                            {_.first(
                              studentCompetencies.filter(
                                (sc) =>
                                  sc.studentId === s.studentId && sc.courseCompetencyId === c.id
                              )
                            )?.countTypeValue || 0}{' '}
                            / {c.countTypeRequirement}
                            <IconButton
                              name={'addToCount'}
                              className={styles.countButton}
                              onClick={(e) => {
                                handleCompetencyCountChange(
                                  s.studentId,
                                  c.id,
                                  (_.first(
                                    studentCompetencies.filter(
                                      (sc) =>
                                        sc.studentId === s.studentId &&
                                        sc.courseCompetencyId === c.id
                                    )
                                  )?.countTypeValue || 0) + 1,
                                  s.email,
                                  s.enrollmentId
                                );
                              }}>
                              <AddIcon />
                            </IconButton>
                            <IconButton
                              name={'removeFromCount'}
                              className={styles.countButton}
                              onClick={(e) => {
                                handleCompetencyCountChange(
                                  s.studentId,
                                  c.id,
                                  (_.first(
                                    studentCompetencies.filter(
                                      (sc) =>
                                        sc.studentId === s.studentId &&
                                        sc.courseCompetencyId === c.id
                                    )
                                  )?.countTypeValue || 0) - 1,
                                  s.email,
                                  s.enrollmentId
                                );
                              }}>
                              <Remove />
                            </IconButton>
                          </>
                        )}

                        {studentCompetencies.filter(
                          (sc) =>
                            sc.studentId === s.studentId &&
                            sc.courseCompetencyId === c.id &&
                            sc.status === 'C'
                        ).length === 0 && (
                          <>
                            <Tooltip title={'Send NYC feedback'}>
                              <button
                                onClick={() =>
                                  setShowFeedbackModal({
                                    ...initialFeedbackModalState,
                                    isNyc: true,
                                    studentId: s.studentId,
                                    competencyId: c.id,
                                    show: true,
                                    // studentCompetency: studentCompetencies.find(
                                    //     (sc) =>
                                    //         sc.studentId === s.studentId &&
                                    //         sc.courseCompetencyId === c.id) ?? null
                                  })
                                }>
                                NYC
                              </button>
                            </Tooltip>
                          </>
                        )}
                        {studentCompetencies.find(
                          (sc) =>
                            sc.studentId === s.studentId &&
                            sc.courseCompetencyId === c.id &&
                            sc.feedbackSent
                        ) && (
                          <Tooltip title={'View feedback sent'}>
                            <button
                              onClick={() =>
                                setShowStudentCompetencyFeedbackModal(
                                  studentCompetencies.filter(
                                    (sc) =>
                                      sc.studentId === s.studentId && sc.courseCompetencyId === c.id
                                  )[0]
                                )
                              }>
                              <EmailIcon />
                            </button>
                          </Tooltip>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Modal
        className={styles.modal}
        open={showFeedbackModal?.show}
        closeAfterTransition
        onClose={() => {
          setShowFeedbackModal(initialFeedbackModalState);
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}>
        <Fade in={showFeedbackModal?.show}>
          <div className={styles.paper}>
            <Box width="10%" ml="auto">
              <IconButton
                aria-label="close"
                onClick={() => setShowFeedbackModal(initialFeedbackModalState)}>
                <Clear />
              </IconButton>
            </Box>
            <Box mb={2} ml={2} mr={2}>
              <span>
                Send{' '}
                <strong>
                  {
                    selectedStudents.find((s) => s.studentId === showFeedbackModal.studentId)
                      ?.firstName
                  }
                </strong>{' '}
                a <strong>{showFeedbackModal.isNyc ? 'NYC' : 'Completion'}</strong> feedback email?
              </span>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Competency</TableCell>
                    <TableCell>
                      <strong>
                        {
                          courseCompetencies.find((cc) => cc.id === showFeedbackModal.competencyId)
                            ?.group1
                        }{' '}
                        -{' '}
                        {
                          courseCompetencies.find((cc) => cc.id === showFeedbackModal.competencyId)
                            ?.competencyTitle
                        }
                      </strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Template</TableCell>
                    <TableCell>
                      <Select
                        value={showFeedbackModal.templateId}
                        onChange={(e) => {
                          const templateId = e.target.value as number;

                          setShowFeedbackModal({
                            ...showFeedbackModal,
                            templateId: templateId,
                            subject: replacePlaceholders(
                              (courseCompetencies.find(
                                (cc) => cc.id === showFeedbackModal.competencyId
                              )?.templates as ICourseCompetencyTemplateModel[]).find(
                                (t) => t.id === templateId
                              )?.emailSubject || ''
                            ),
                            body: replacePlaceholders(
                              (courseCompetencies.find(
                                (cc) => cc.id === showFeedbackModal.competencyId
                              )?.templates as ICourseCompetencyTemplateModel[]).find(
                                (t) => t.id === templateId
                              )?.emailContent || ''
                            ),
                          });
                        }}>
                        <MenuItem disabled value={0} defaultChecked={true}>
                          Select
                        </MenuItem>
                        {courseCompetencies
                          .find((c) => c.id === showFeedbackModal.competencyId)
                          ?.templates.filter(
                            (t: ICourseCompetencyTemplateModel) =>
                              t.type === (showFeedbackModal.isNyc ? 'NYC' : 'C')
                          )
                          .map((t: ICourseCompetencyTemplateModel, i: number) => {
                            return (
                              <MenuItem key={i} value={t.id}>
                                {t.title}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Subject</TableCell>
                    <TableCell>
                      <input
                        type="text"
                        value={showFeedbackModal.subject}
                        onChange={(e) =>
                          setShowFeedbackModal({ ...showFeedbackModal, subject: e.target.value })
                        }
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Body</TableCell>
                    <TableCell>
                      <textarea
                        onChange={(e) =>
                          setShowFeedbackModal({
                            ...showFeedbackModal,
                            body: e.target.value,
                          })
                        }
                        rows={5}
                        value={showFeedbackModal.body}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>
                      Please note that the competency status change has already been saved. Closing
                      or skipping this modal will not revert the change.
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <button
                disabled={!showFeedbackModal.subject || !showFeedbackModal.body}
                onClick={sendFeedbackEmail}>
                Send
              </button>
              <button onClick={() => setShowFeedbackModal(initialFeedbackModalState)}>Skip</button>
            </Box>
          </div>
        </Fade>
      </Modal>

      <Modal
        className={styles.modal}
        open={showStudentCompetencyFeedbackModal != null}
        closeAfterTransition
        onClose={() => {
          setShowStudentCompetencyFeedbackModal(null);
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}>
        <Fade in={showStudentCompetencyFeedbackModal != null}>
          <div className={styles.paper}>
            <Box width="10%" ml="auto">
              <IconButton
                aria-label="close"
                onClick={() => setShowStudentCompetencyFeedbackModal(null)}>
                <Clear />
              </IconButton>
            </Box>
            <Box mb={2} ml={2} mr={2}>
              <article style={{ whiteSpace: 'pre-wrap' }}>
                {showStudentCompetencyFeedbackModal?.feedbackSent.replace(/<br>/g, '\n')}
              </article>

              <button onClick={() => setShowStudentCompetencyFeedbackModal(null)}>Back</button>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
